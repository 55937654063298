import React from 'react';
import {Space, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";

const HotelBookingList = ({bookingList, isLoading, pagination, handleTableChange}) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'GuestNum',
            dataIndex: 'fidelioGuestNum',
            key: 'fidelioGuestNum'
        },
        {
            title: 'Отель',
            dataIndex: 'hotelTitle',
            render: (text, record) => {
                return record.hotel.title
            }
        },
        {
            title: 'Номер',
            dataIndex: 'roomTitle',
            render: (text, record) => {
                return record.room.title
            }
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'CONFIRMED') {
                    return <Tag icon={<CheckCircleOutlined spin/>} color="success">
                        Оплачен
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                } else if (record.status === 'ARCHIVED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        В архиве
                    </Tag>
                }
            }
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userId}</Link>
                </Space>
            )
        },
        {
            title: 'Дата заезда',
            dataIndex: 'arrivalDate',
            key: 'arrivalDate'
        },
        {
            title: 'Дата выезда',
            dataIndex: 'departureDate',
            key: 'departureDate'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {/*<Button onClick={() => router.push(`/hotels/${record.id}`)}>Редактировать</Button>*/}
                </Space>
            )
        }
    ];

    return (
        <Table
            pagination={pagination}
            columns={columns}
            dataSource={bookingList}
            rowKey='id'
            loading={isLoading}
            onChange={handleTableChange}
        />
    );
};

export default HotelBookingList;