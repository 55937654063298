import React, {useEffect, useState} from 'react';
import {Button, Image, Space, Table} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import {Link, useHistory} from "react-router-dom";
import ChangePasswordModal from "../../components/users/ChangePasswordModal";
import Search from "antd/lib/input/Search";

const Users = () => {

    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Tng Profile Id',
            dataIndex: 'tngProfileId',
            key: 'tngProfileId'
        },
        {
            title: 'Аватар',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={75}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Уровень',
            dataIndex: 'currentLevel',
            key: 'currentLevel'
        },
        {
            title: 'Доступно бонусов',
            dataIndex: 'availableBonuses',
            key: 'availableBonuses'
        },
        {
            title: 'Доступный депозит',
            dataIndex: 'availableDeposit',
            key: 'availableDeposit'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link type="primary" to={`/users/${record.id}`}>Просмотр</Link>
                    <Button onClick={() => showModal(record)}>Изменить пароль</Button>
                </Space>
            )
        }
    ]

    const [fetchUsers, isUsersLoading] = useFetching(async (page, size, searchTerm) => {
        const {data} = await UserService.getAll(page, size, searchTerm);
        setUsers(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchUsers(pagination.current, pagination.pageSize, searchTerm);
    }

    const [visible, setVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const showModal = (record) => {
        setSelectedUser(record);
        setVisible(true);
    };


    useEffect(() => {
        fetchUsers(pagination.current, pagination.pageSize, searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = value => setSearchTerm(value);

    useEffect(() => {
        fetchUsers(pagination.current, pagination.pageSize, searchTerm);
    }, [searchTerm])

    return (
        <>
            <Search placeholder="Поиск по ФИО, номеру тел., Tng Profile Id или email" onSearch={onSearch} />
            <Table columns={columns}
                   dataSource={users}
                   rowKey='id'
                   loading={isUsersLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
            <ChangePasswordModal
                user={selectedUser}
                visible={visible}
                setVisible={setVisible}
            />
        </>
    );
};

export default Users;