import Dashboard from "../pages/Dashboard";
import Users from "../pages/users/Users";
import Login from "../pages/login/Login";
import AxessMetaTariffs from "../pages/axess/AxessMetaTariffs";
import UserIdPage from "../pages/users/UserIdPage";
import Restaurants from "../pages/restaurants/Restaurants";
import Live from "../pages/content/Live";
import Onboarding from "../pages/content/Onboarding";
import News from "../pages/content/News";
import RestaurantIdPage from "../pages/restaurants/RestaurantIdPage";
import RestaurantsReservation from "../pages/restaurants/RestaurantsReservation";
import Hotels from "../pages/hotel/Hotels";
import HotelIdPage from "../pages/hotel/HotelIdPage";
import Rooms from "../pages/hotel/Rooms";
import RoomIdPage from "../pages/hotel/RoomIdPage";
import HotelBooking from "../pages/hotel/HotelBooking";
import AxesMetaTariffIdPage from "../pages/axess/AxesMetaTariffIdPage";
import Payments from "../pages/payments/Payments";
import TicketCategories from "../pages/tickets/TicketCategories";
import TicketTypes from "../pages/tickets/TicketTypes";
import TicketTypeIdPage from "../pages/tickets/TicketTypeIdPage";
import Notifications from "../pages/notification/Notifications";
import NotificationIdPage from "../pages/notification/NotificationIdPage";
import ProductCategories from "../pages/merchant/ProductCategories";
import Products from "../pages/merchant/Products";
import ProductOrders from "../pages/merchant/ProductOrders";
import ProductCategoryIdPage from "../pages/merchant/ProductCategoryIdPage";
import ProductIdPage from "../pages/merchant/ProductIdPage";
import SuriOrders from "../pages/suri/SuriOrders";
import RentItems from "../pages/rent/RentItems";
import RentItemOrders from "../pages/rent/RentItemOrders";
import RentItemIdPage from "../pages/rent/RentItemIdPage";
import Orders from "../pages/orders/Orders";
import OrderIdPage from "../pages/orders/OrderIdPage";
import Feedbacks from "../pages/reviews/Feedbacks";
import ApprovedFeedbacks from "../pages/reviews/ApprovedFeedbacks";
import PartnerLoyalty from "../pages/partnerloyalty/PartnerLoyalty";

export const privateRoutes = [
    {path: '/', component: Dashboard, exact: true},
    {path: '/users', component: Users, exact: true},
    {path: '/users/:id', component: UserIdPage, exact: true},
    {path: '/axess/meta-tariffs', component: AxessMetaTariffs, exact: true},
    {path: '/axess/meta-tariffs/:id', component: AxesMetaTariffIdPage, exact: true},
    {path: '/payments', component: Payments, exact: true},
    {path: '/notifications', component: Notifications, exact: true},
    {path: '/notifications/:id', component: NotificationIdPage, exact: true},
    {path: '/orders', component: Orders, exact: true},
    {path: '/orders/:id', component: OrderIdPage, exact: true},
    {path: '/restaurants', component: Restaurants, exact: true},
    {path: '/restaurants/reservation', component: RestaurantsReservation, exact: true},
    {path: '/restaurants/:id', component: RestaurantIdPage, exact: true},
    {path: '/content/live', component: Live, exact: true},
    {path: '/content/onboarding', component: Onboarding, exact: true},
    {path: '/content/news', component: News, exact: true},
    {path: '/hotels', component: Hotels, exact: true},
    {path: '/hotels/booking', component: HotelBooking, exact: true},
    {path: '/hotels/:id/rooms', component: Rooms, exact: true},
    {path: '/hotels/:hotelId/rooms/:id', component: RoomIdPage, exact: true},
    {path: '/hotels/:id', component: HotelIdPage, exact: true},
    {path: '/orders/categories', component: TicketCategories, exact: true},
    {path: '/orders/types', component: TicketTypes, exact: true},
    {path: '/orders/types/:id', component: TicketTypeIdPage, exact: true},
    {path: '/merchant/categories', component: ProductCategories, exact: true},
    {path: '/merchant/categories/:id', component: ProductCategoryIdPage, exact: true},
    {path: '/merchant/products', component: Products, exact: true},
    {path: '/merchant/products/:id', component: ProductIdPage, exact: true},
    {path: '/merchant/orders', component: ProductOrders, exact: true},
    {path: '/suri/orders', component: SuriOrders, exact: true},
    {path: '/rent/items', component: RentItems, exact: true},
    {path: '/rent/items/:id', component: RentItemIdPage, exact: true},
    {path: '/rent/orders', component: RentItemOrders, exact: true},
    {path: '/feedbacks', component: Feedbacks, exact: true},
    {path: '/approved-feedbacks', component: ApprovedFeedbacks, exact: true},
    {path: '/partner-loyalty', component: PartnerLoyalty, exact: true},
]

export const publicRoutes = [
    {path: '/login', component: Login, exact: true},
]